import React, { useEffect } from 'react';
import { CreateCustomerRequestModel } from '@models/customerRequests/formModels';
import { onClickParseXlsx } from '@helpers/customerSearchFormHelpers';
import { CargoDimensionDto } from '@models/customerRequests/customerRequestModels';
import { Field, FieldProps, FormikProps } from 'formik';
import { Input, Label } from 'reactstrap';
import { GetNatureOfCargoDto, GetSpecialHandlingCodeDto } from '@models/cargoes';
import { useTranslation } from 'react-i18next';
import nameof from 'ts-nameof.macro';
import s from './style.module.scss';
import i18n from '@i18n';
import StaticSelect from '@components/select/StaticSelect';
import Switcher from '@components/Switcher';
import DimensionsService from '@services/DimensionsService';
import MultiSwitch from '@components/MultiSwitch';
import FieldError from '@scenes/customerRequest/searchForm/components/FieldError';
import { Button as Btn, Link, PageHeader, TextArea } from '@root/components';
import { ReactComponent as IconTable } from '@material-design-icons/svg/round/description.svg';
import { ReactComponent as IconScan } from '@material-design-icons/svg/round/document_scanner.svg';
import { ReactComponent as IconDownload } from '@material-design-icons/svg/round/download.svg';
import { ParseDimsModal } from '@root/modals';
import { Dims } from '@scenes/request/components';
import { UnitModes } from '@root/enum';
import { UnitOption } from '@root/types';
import { recalculateCargoDetails } from '@scenes/request/helpers';

type CargoDetailsProps = {
    selectedCodes: GetSpecialHandlingCodeDto[];
    availableCodes: GetSpecialHandlingCodeDto[];
    availableNatures: GetNatureOfCargoDto[];
    formikProps: FormikProps<CreateCustomerRequestModel>;
    onChangeCodes: (codes: string[]) => void;
};

const unitOptions: UnitOption[] = [
    { labelRes: 'units.full.m', value: UnitModes.Meters },
    { labelRes: 'units.full.cm', value: UnitModes.Centimeters },
    { labelRes: 'units.full.mm', value: UnitModes.Millimeters },
];

const CargoDetails = (props: CargoDetailsProps): JSX.Element => {
    const { formikProps } = props;
    const fileRef = React.createRef<HTMLInputElement>();
    const [isParseModalOpen, toggleParseModalOpen] = React.useState(false);
    const [dimensionsAsText, setDimensionsAsText] = React.useState('');
    const [unitOfMeasure, setUnitOfMeasure] = React.useState<UnitModes>(UnitModes.Centimeters);
    const { t } = useTranslation();
    
    useEffect(() => {
        recalculateCargoDetails(formikProps);
    }, [formikProps.values.hasCargoDimensions, formikProps.values.cargoDimensions]);

    const uploadExcel = (): void => {
        if (fileRef.current !== null && fileRef.current.click) {
            fileRef.current.click();
        }
    };

    const parseDimensions = async (): Promise<void> => {
        const rows = await new DimensionsService().parse(dimensionsAsText);
        const dtos: CargoDimensionDto[] = rows.data
            .map((r) => ({ ...r, amount: r.pieces }));

        if (unitOfMeasure === UnitModes.Millimeters) {
            dtos.forEach((r) => {
                r.height = (r.height || 0) / 10;
                r.length = (r.length || 0) / 10;
                r.width = (r.width || 0) / 10;
            });
        } else if (unitOfMeasure === UnitModes.Meters) {
            dtos.forEach((r) => {
                r.height = (r.height || 0) * 100;
                r.length = (r.length || 0) * 100;
                r.width = (r.width || 0) * 100;
            });
        }

        dtos.forEach((r) => {
            r.height = Math.round(r.height);
            r.length = Math.round(r.length);
            r.width = Math.round(r.width);
        });

        if (rows?.data?.length) {
            await formikProps.setFieldValue(nameof.full<CreateCustomerRequestModel>(x => x.cargoDimensions), dtos, true);
            await formikProps.setFieldValue(nameof.full<CreateCustomerRequestModel>(x => x.hasCargoDimensions), true);
            await formikProps.setFieldValue(nameof.full<CreateCustomerRequestModel>(x => x.useTotalWeight), dtos.reduce((r, x) => r + x.weight, 0) <= 0);
        }

        setDimensionsAsText('');
        toggleParseModalOpen(false);
    };

    const onCloseModal = () => {
        toggleParseModalOpen(false);
        setDimensionsAsText('');
        setUnitOfMeasure(UnitModes.Centimeters);
    };

  return (
    <>
      <div className={s.formCard}>
        <PageHeader title={t('request.cargoDetails')} size='subHeader' />
        <div className={s.row}>
          <div className={s.col}>
            <StaticSelect<GetNatureOfCargoDto>
              label={t('request.natureOfCargo')}
              name={nameof.full<CreateCustomerRequestModel>((x) => x.natureOfCargo)}
              isMulti={false}
              value={props.availableNatures.filter((x) => x.id === formikProps.values.natureOfCargo)}  // To-Do :: set correct Initial value
              onChange={(v) => {
                // сохраняем значение в текущем поле
                formikProps.setFieldValue(
                  nameof.full<CreateCustomerRequestModel>((x) => x.natureOfCargo),
                  (v && v.length ? v[0].id : undefined),
                );
                // заполняем Goods description
                formikProps.setFieldValue(
                  nameof.full<CreateCustomerRequestModel>((x) => x.goodsDescription),
                  (v && v.length ? v[0].description : undefined),
                );
                // обновляем SHC
                if (v && v.length && v[0].shcId) {
                  props.onChangeCodes([v[0].shcId]);
                }
              }}
              filterPropertyName={'name'}
              selectedLabel={(x) => <>{x?.name}</>}
              availableOptions={props.availableNatures}
              placeholder={t('request.natureOfCargo_placeholder')}
              formatOptionLabel={(option) => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ flex: 1 }}>
                    {option.name}
                  </div>
                  <div style={{ paddingLeft: '10px', color: '#ccc', flexBasis: '40px' }}>
                    {props.availableCodes.filter(c => c.id == option.shcId)[0]?.code}
                  </div>
                </div>
              )}
            />
            <FieldError
              error={formikProps.errors['natureOfCargo'] as string}
              isTouched={formikProps.touched['natureOfCargo'] as boolean}
            />
          </div>
          <Field name={nameof.full<CreateCustomerRequestModel>((x) => x.goodsDescription)}>
            {({ field, form }: FieldProps): JSX.Element => (
              <div className={s.col}>
                <Label for={field.name}>{t('request.goodsDescription')}</Label>
                <Input
                  maxLength={50}
                  placeholder={t('request.goodsDescription_placeholder')}
                  className={form.errors[field.name] && form.touched[field.name] ? 'inputControlError' : ''}
                  {...field}
                  value={field.value || ''}
                />
                <FieldError
                  error={form.errors[field.name] as string}
                  isTouched={form.touched[field.name] as boolean}
                />
              </div>
            )}
          </Field>
        </div>
        <div className={s.row}>
          <div className={s.col}>
            <StaticSelect<GetSpecialHandlingCodeDto>
              label={t('shc.shc')}
              name={nameof.full<CreateCustomerRequestModel>((x) => x.specialHandlingCodeIds)}
              isMulti
              value={props.selectedCodes}
              onChange={c => props.onChangeCodes(c.map(a => a.id))}
              filterPropertyName={'name'}
              optionLabel={(x) => <>{x?.name}</>}
              selectedLabel={(x) => <>{x?.code}</>}
              placeholder={t('shc.chooseShc')}
              availableOptions={props.availableCodes}
              checkboxes={true}
            />
            <FieldError
              error={formikProps.errors['specialHandlingCodeIds'] as string}
              isTouched={formikProps.touched['specialHandlingCodeIds'] as boolean}
            />
          </div>
          <div className={s.col}>
            <div className={s.fields}>
              <Field name={nameof.full<CreateCustomerRequestModel>((x) => x.totalPlaces)}>
                {({ field, form }: FieldProps): JSX.Element => (
                  <div className='inputControl'>
                    <Label for={field.name}>{t('request.packagePlaces')}</Label>
                    <Input
                      placeholder=''
                      className={form.errors[field.name] && form.touched[field.name] ? 'inputControlError' : ''}
                      {...field}
                      value={field.value || ''}
                    />
                    <FieldError
                      error={form.errors[field.name] as string}
                      isTouched={form.touched[field.name] as boolean}
                    />
                  </div>
                )}
              </Field>
              <Field name={nameof.full<CreateCustomerRequestModel>((x) => x.totalWeight)}>
                {({ field, form }: FieldProps): JSX.Element => (
                  <div className='inputControl'>
                    <div className={s.totalWeightLabel}>
                      <Label for={field.name}>{t('request.cargoWeightTotal')}</Label>
                    </div>
                    <Input
                      placeholder={t('units.kg')}
                      className={form.errors[field.name] && form.touched[field.name] ? 'inputControlError' : ''}
                      {...field}
                      value={field.value || ''}
                    />
                    <FieldError
                      error={form.errors[field.name] as string}
                      isTouched={form.touched[field.name] as boolean}
                    />
                  </div>
                )}
              </Field>
              <Field name={nameof.full<CreateCustomerRequestModel>((x) => x.totalVolume)}>
                {({ field, form }: FieldProps): JSX.Element => (
                  <div className='inputControl'>
                    <Label for={field.name}>{t('request.cargoVolume')}</Label>
                    <Input
                      placeholder={t('units.m3')}
                      className={form.errors[field.name] && form.touched[field.name] ? 'inputControlError' : ''}
                      {...field}
                      value={field.value || ''}
                    />
                    <FieldError
                      error={form.errors[field.name] as string}
                      isTouched={form.touched[field.name] as boolean}
                    />
                  </div>
                )}
              </Field>
            </div>
          </div>
        </div>
        <div className={s.row}>
          <div className={s.col}>
            <div className={s.switch}>
              <div className={s.switchItem}>
                <Field name={nameof.full<CreateCustomerRequestModel>((x) => x.isCargoStacked)}>
                  {({ field }: FieldProps): JSX.Element => (
                    <Switcher
                      {...field}
                      id={nameof.full<CreateCustomerRequestModel>((x) => x.isCargoStacked)}
                      checked={field.value}
                      texton='admin.settings.yes'
                      textoff='admin.settings.no'
                    />
                  )}
                </Field>
                <Label for={nameof.full<CreateCustomerRequestModel>((x) => x.isCargoStacked)}>
                  {t('request.isCargoStacked')}
                </Label>
              </div>
              <div className={s.switchItem}>
                <Field name={nameof.full<CreateCustomerRequestModel>((x) => x.isTurnable)}>
                  {({ field }: FieldProps): JSX.Element => (
                    <Switcher
                      {...field}
                      id={nameof.full<CreateCustomerRequestModel>((x) => x.isTurnable)}
                      checked={field.value}
                      texton='admin.settings.yes'
                      textoff='admin.settings.no'
                    />
                  )}
                </Field>
                <Label for={nameof.full<CreateCustomerRequestModel>((x) => x.isTurnable)}>
                  {t('request.isTurnable')}
                </Label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.formCard}>
        <div className={s.dim}>
          <PageHeader title={t('request.headers.dimensions')} size='subHeader' />
          {formikProps.values.hasCargoDimensions &&
            <div className={s.buttons}>
              <Btn
                type='text'
                icon={<IconScan fill='currentColor' />}
                variant='warning'
                onClick={(e) => {
                  e.preventDefault();
                  toggleParseModalOpen(true);
                }}>
                {t('request.parseDims')}
              </Btn>
              <Field name='file'>
                {({ field, form }: FieldProps): JSX.Element => (
                  <Input
                    name={field.name}
                    type='file'
                    className='d-none'
                    innerRef={fileRef}
                    onChange={(e) => onClickParseXlsx(e, form)}
                  />
                )}
              </Field>
              <Btn
                type='text'
                icon={<IconTable fill='currentColor' />}
                variant='success'
                onClick={(e) => {
                  e.preventDefault();
                  uploadExcel();
                }}>
                {t('request.excel')}
              </Btn>
              <Link
                className={s.downloadBtn}
                icon={<IconDownload fill='currentColor' />}
                download='dimensions.xlsx'
                href={`${process.env}/dimensions_${i18n.language}.xlsx`}>
                {t('request.excelTemplate')}
              </Link>
            </div>
          }
        </div>

        <Dims formikProps={formikProps} />

      </div>
      <ParseDimsModal
        open={isParseModalOpen}
        confirmText={t('request.parse')}
        onClose={onCloseModal}
        onConfirm={parseDimensions}>
        <div className={s.rowSection}>
          <TextArea
            row={10}
            onChange={e => setDimensionsAsText(e)}
            value={dimensionsAsText}
          />
        </div>
        <div className={s.rowSection}>
          <MultiSwitch
            onChange={(v: UnitModes) => setUnitOfMeasure(v)}
            defaultValue={unitOfMeasure}
            options={unitOptions}
          />
        </div>
      </ParseDimsModal>
    </>
  );
};

export default CargoDetails;