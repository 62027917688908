import { TenantRuResources } from '@helpers/tenantsHelper';

const common = {
    actions: 'Действия',
    backToList: 'К списку',
    ok: 'OK',
    cancel: 'Отмена',
    start: 'Начать',
    create: 'Создать',
    created: 'Создан(-но)',
    update: 'Обновить',
    updated: 'Обновлен(-но)',
    imported: 'Импортирован(-но)',
    name: 'Имя',
    address: 'Адрес',
    select: 'Выбрать',
    success: 'Успешно',
    activate: 'Активировать',
    deactivate: 'Деактивировать',
    deactivateLabel: 'Укажите обоснование для деактивации',
    activated: 'Активирован',
    deactivated: 'Деактивирован',
    changesSaved: 'Изменения сохранены',
    draftSaved: 'Черновик сохранен',
    changesReset: 'Изменения отменены',
    remove: 'Удалить',
    removed: 'Удален(-но)',
    restore: 'Восстановить',
    price: 'Цена',
    airline: 'Авиакомпания',
    number: 'Номер',
    yes: 'Да',
    no: 'Нет',
    save: 'Сохранить',
    undo: 'Отменить изменения',
    harmonizedCommodityCodes: 'HS CODE',
    sameAsLegalAddress: 'Совпадает с юр. адресом',
    areDirectorAndAccountantTheSame: 'Ген. директор и ответственный за взаиморасчёты — одно лицо',
    chargeableWeight: 'Оплачиваемый вес',
    transportationCost: 'Стоимость перевозки',
    transportationCostPrev: 'Предварительная стоимость перевозки',
    costPerUnit: 'Ставка за {unit}',
    unableToChangePermissionsForYourself: 'Невозможно изменить разрешения для самого себя.',
    changeRequestParams: 'Попробуйте изменить параметры запроса и повторите попытку',
    contact: {
        edit: 'Редактирование сотрудника',
        create: 'Создание сотрудника',
        createNew: 'Добавить нового сотрудника',
        activated: 'Активирован',
        deactivated: 'Деактивирован',
        canLogin: 'Доступ разрешён',
        canNotLogin: 'Доступ запрещён',
    },
    contactPermissionTypes: {
        None: 'Нет',
        All: 'Все',
        UpdatePermissions: 'Изменение разрешений',
        UpdateOrganizationInfo: 'Изменение информации об организации',
        CreateContact: 'Создание сотрудника',
        UpdateContact: 'Изменение информации о сотруднике',
    },
    interactionTypes: {
        Commercial: 'Коммерческие вопросы',
        Operational: 'Оперативная работа',
        Payments: 'Взаиморасчеты',
        Debts: 'Долги',
        Director: 'Руководитель организации',
        Documents: 'Документация',
        Administrator: 'Администратор',
        hints: {
            Commercial: 'Обсуждение коммерческих вопросов и рассылка тарифного предложения',
            Operational:
                'Оперативная работа и отправка автоматических уведомлений о бронировании и отслеживании грузов',
            Payments: 'Взаиморасчеты и отправка бухгалтерских документов в электронном виде',
            Debts: 'Ответственный за долги',
            Director: 'Единоличный исполнительный орган общества',
            Documents: 'Укажите ответственного за отправку и получение первичной учетной документации',
            Administrator: 'Управление пользователями и правами в системе',
        },
    },
    jobPositionTypes: {
        DirectorOrItsDeputy: 'Руководитель',
        FinancialDirector: 'Финансовый директор',
        CommercialDirector: 'Коммерческий директор',
        HeadOfDepartment: 'Руководитель (отдела, филиала)',
        ChiefAccountant: 'Ответственный за взаиморасчеты',
        Accountant: 'Старший бухгалтер / бухгалтер',
        TransportationManager: 'Менеджер по перевозкам',
        LogisticsManager: 'Менеджер по логистике, ВЭД, таможенному оформлению',
        AccountManager: 'Менеджер по работе с клиентами',
        SalesManager: 'Менеджер по бронированию и продаже',
        Ceo: 'CEO',
        Cfo: 'CFO',
        KeyAccountManager: 'Key account manager',
        CustomerService: 'Customer Service',
        TransportCoordinator: 'Transport coordinator',
        Other: 'Прочее',
    },
    waysToGetAccountingDoc: {
        None: '',
        ByEmail: 'По почте',
        ByEdm: 'Посредством ЭДО',
    },
    exceptions: {
        ConflictException: {
            AnotherContactWithSameJobPositionAlreadyExists:
                'Другой сотрудник ({1} {2} c Email {3}) уже имеет данную должность.',
            EmailWithSameLanguageAlreadyExists: 'Эл. почта с таким языком уже используется.',
        },
        InvalidLoginException: {
            InvalidLoginOrPassword: 'Неверный логин или пароль.',
            LockoutEnabled: 'Вход в данную учётную запись невозможен.',
        },
        AccountRegistrationException: {
            UserAlreadyExists: 'Пользователь с таким email уже существует.',
            ContactAssignedToAnotherOrganization: 'Пользователь привязан к другой организации.',
            UnexpectedError: 'Не удалось зарегистрироваться, пожалуйста, свяжитесь с нами.',
            ContactLostRequiredInteractionType:
                'Перед удалением обязательного вида взаимодействия, его необходимо присвоить другому контакту',
        },
        UnprocessableEntityException: {
            CouldNotSendEmailMessage:
                'Невозможно отправить сообщение на адрес эл. почты {1}. Возможно, он указан неверно.',
            BookingDeniedWithoutContract:
                'Для бронирования необходимо заключение договора. Просьба обратиться на sales@ffcargo.com',
            TariffOrFeeExpired:
                'Данные устарели. Необходимо сформировть новый запрос.',
        },
        'Master AirWaybill has draft status.': 'Основная авианакладная имеет статус черновика.',
    },
    identityCodes: {
        PasswordRequiresNonAlphanumeric: 'Пароль должен содержать хотя бы один не буквенно-цифровой символ.',
        PasswordRequiresLower: 'Пароль должен содержать хотя бы одну не заглавную букву (\'a\'-\'z\').',
        PasswordRequiresUpper: 'Пароль должен содержать хотя бы одну заглавную букву (\'A\'-\'Z\').',
        PasswordRequiresDigit: 'Пароль должен содержать хотя бы одну цифру (\'0\'-\'9\').',
        PasswordTooShort: 'Пароль должен иметь длину в {0} символ(а), или больше.',
    },
    shc: {
        chooseShc: 'Выберите код обработки',
        shc: 'Специальный код обработки',
    },
    accreditation: {
        Granted: 'Благодарим за предоставление актуальных данных.\nТеперь вы можете приступить к работе!',
        GrantedNoBooking: 'Благодарим за предоставление актуальных данных.\nТеперь вы можете приступить к работе!',
        Pending:
            'Спасибо за предоставление информации о Вашей компании.\nНаш сотрудник свяжется с Вами в ближайшее время для обсуждения дальнейшего сотрудничества.',
        Rejected: 'Благодарим за предоставление данных о Вашей компании.\nК сожалению, данные были отклонены!',
        Blocked: 'Благодарим за предоставление данных о Вашей компании.\nСожалеем, но ваша организация заблокирована!',
        message: 'Для того чтобы продолжить работу с сайтом, просьба заполнить форму ниже.',
        employeesSubHeader:
            'Укажите сотрудников в соответствии с зоной их ответственности.',
        contacts: {
            create: 'Создать',
            interactionTypesText: 'Зона ответственности',
            employee: 'Сотрудник',
            jobPosition: 'Должность',
            email: 'Эл. почта',
            buttonDeleteRow: 'Удалить',
            errors: {
                ShouldContainPaymentsInteractionType: 'Необходимо указать ответственного за взаиморасчеты',
                ShouldContainDirectorInteractionType: 'Необходимо указать руководителя',
                ShouldContainCommercialInteractionType: 'Необходимо указать ответственного по коммерческим вопросам',
                ShouldContainOperationalInteractionType: 'Необходимо указать ответственного по оперативной работе',
            },
        },
        interactionRequired: 'Необходимо указать сотрудника, ответственного за этот блок',
        thanks: 'ООО «ФФ Карго Сервис» благодарит Вас за предоставленную информацию и надеется на долгое и плодотворное сотрудничество.',
        timeoutSplash: 'Обновление данных об организации... Осталось {timeLeft} cекунд.',
    },
    preferences: 'Предпочтения',
    changePasswordText: 'Сменить пароль',
    add: 'Добавить',
    accountSettings: 'Личный кабинет',
    personalInfo: 'Личная информация',
    organizationText: 'Организация',
    employees: 'Сотрудники',
    employee: 'Сотрудник',
    backToEmployees: 'Назад к сотрудникам',
    permissions: 'Разрешения',
    firstName: 'Имя',
    lastName: 'Фамилия',
    jobPosition: 'Должность',
    email: 'Эл. почта',
    phone: 'Телефон',
    mobilePhone: 'Мобильный телефон',
    sender: 'Отправитель',
    interactionTypesText: 'Типы взаимодействия',
    preferredLanguage: 'Предпочитаемый язык',
    selectLanguage: 'Выбрать язык',
    selectInteractionTypes: 'Выберите типы взаимодействий',
    selectJobPosition: 'Выберите должность',
    selectWayToGetDocs: 'Выберите способ',
    legalInfo: 'Юр. информация',
    contactInfo: 'Контактная информация',
    financialInfo: 'Фин. информация',
    additionalInfo: {
        title: 'Дополнительная информация',
        employeesCount: 'Количество сотрудников в организации',
        currentCarriers: 'С какими перевозчиками сотрудничаете на данный момент',
        mainDirections: 'Основные направления',
        expectedVolumes: 'Ожидаемые объемы в рамках планируемого сотрудничества',
    },

    password: {
        get: 'Получить пароль',
        noPassword:
            'Ваш пароль не задан. Нажмите на кнопку, чтобы получить инструкции для получения пароля на вашу почту.',
        sendEmail: 'Отправить письмо',
        emailSent: 'Письмо с инструкциями на вашем почтовом ящике.',
        emailSendFail: 'Не удалось отправить письмо, пожалуйста, попытайтесь снова.',

        changePassword: 'Смена пароля',
        currentPassword: 'Текущий пароль',
        newPassword: 'Новый пароль',
        confirmPassword: 'Повторите пароль',
        passwordsMismatch: 'Пароли не совпадают',
        passwordChanged: 'Пароль изменён',
    },

    organization: {
        shortName: 'Сокращенное наименование',
        uniqueNumber: 'ИНН',
        vatNumber: 'КПП',
        regNumber: 'ОГРН',
        primaryContact: 'Основной контакт',
        fullName: 'Полное наименование',
        checkingAccount: 'Расчетный счет',
        bankName: 'Банк',
        correspondentAccount: 'Кор. Счет',
        wayToGetAccountingDocs: 'Способ получения первичных документов',
        edmName: 'Оператор ЭДО',
        edmId: 'Идентификатор ЭДО',
        bik: 'БИК',
        dateIncorporation: 'Дата регистрации компании',
        legalAddress: 'Юридический адрес',
        mailingAddress: 'Почтовый адрес',
        officeAddress: 'Адреса операционных офисов и рабочий телефон',
        websiteUrl: 'Сайт',
        mainActivity: 'Основной вид деятельности',
        phone: 'Телефон',
    },

    upload: {
        maxFileCountReached: 'Загружено максимальное количество файлов.',
        uploadFiles: 'Загрузить файлы',
        attachedFiles: 'Прикреплённые файлы',
        cantUploadFile: 'Невозможно загрузить файл.',
        dropFilesHere: 'Бросьте сюда файл',
        dragAndDropFileHere: 'Бросьте сюда файл или нажмите здесь, чтобы выбрать его',
        uploadSuccessful: 'Загрузка завершена',
        uploadingWarning: 'Подождите, идет загрузка файла на сервер',
    },
    externalLogin: {
        accessDenied: 'Доступ в Cargo booking запрещён.',
        userNotExist: 'Пользователь c email {email} не найден, пожалуйста, ',
        register: 'зарегистрируйтесь',
    },
    options: {
        loadingOptions: 'Загрузка опций...',
        loadingOptionsTemplate: 'Загрузка {itemType}...',
        noOptions: 'Нет доступных опций.',
        noOptionsTemplate: 'Нет доступных {itemType}.',
        specifyAtLeast3Chars: 'Укажите хотя бы 3 символа для поиска',
    },
    paging: {
        onPage: 'Отображать на странице:',
        first: 'Начало',
        last: 'Конец',
    },
    sorting: {
        none: 'Отсутствует',
        ascending: 'По возрастанию',
        descending: 'По убыванию',
        sorting: 'Сортировка',
        name: 'Название',
        dateCreated: 'Дата создания',
    },
    validation: {
        required: 'Обязательное поле',
        invalidAwbNumberChecksum: 'Ошибка контрольной суммы номера',
        invalidAwbNumberAirlineIata: 'Несоответствие префикса выбранной авиакомпании',
        email: 'Введите корректный Email.',
        organizationUniqueNumber: 'Некорректный ИНН',
        unknownError: 'Неизвестная ошибка',
        numberType: 'Введите число',
        positive: 'Положительное значение',
        integer: 'Укажите целое число',
        contactDuplicateEmail: 'Один и тот же Email используется для разных контактов',
        exactLength: 'Поле должно содержать ${length} символ(-ов)',
        invalidFormat: 'Поле имеет неверный формат',
        tooShortHSCodes: 'Одна из строк слишком короткая, минимум 6 символов',
        tooLongHSCodes: 'Одна из строк слишком длинная, максимум 18 символов',
        tooMuchHSCodes: 'Слишком много строк. Максимум - 12',
        'One or more validation errors occured.': 'Возникло несколько ошибок.',
        'INN is invalid.': 'Некорректный ИНН',
        lettersExpected: 'В названии не могут быть только числа',
        invalidAddress: 'Адрес заполнен некорректно',
        countryRequired: 'Укажите страну',
        invalidCodeIata: 'Код IATA не указан или в неверном формате',
        'Airwaybill number must start with airline IATA prefix.': 'Номер авианакладной должен начинаться с префикса кода IATA авиакомпании.',
        'Number should be unique.': 'Номер должен быть уникальным.',
        atLeastOnePhoneRequired: 'Необходимо заполнить хотя бы один телефон'
    },
    units: {
        kg: 'кг',
        cm: 'см',
        pc: 'шт',
        mc: 'м3',
        mm: 'мм',
        m3: 'м³',
        lb: 'lb',
        min: 'Мин.',
        unit: 'шт',
        full: {
          cm: 'Сантиметры',
          mm: 'Миллиметры',
          m: 'Метры',
        }
    },
    viewMode: {
        title: 'Режим просмотра',
        own: 'Только мои',
        all: 'Все',
    },
    leftMenu: {
        publicSite: 'Cargobooking365 Public Website',
        homePage: 'Главная страница',
        sendRequest: 'Поиск маршрутов и бронирование',
        account: 'Личный кабинет',
        bookings: 'История бронирований',
        requestsHistory: 'История запросов',
        awbPanel: 'AWB',
        adminPanel: 'Панель администратора',
    },
    login: {
        header: TenantRuResources.loginHeader,
        subHeader: 'Добро пожаловать! Пожалуйста, войдите или зарегистрируйтесь',
        loginPlaceholder: 'Введите email...',
        passwordPlaceholder: 'Введите пароль...',
        rememberMe: 'Запомнить меня',
        forgotPwd: 'Забыли пароль?',
        logIn: 'Войти',
        signUp: 'Зарегистрироваться',
        useLoginSrv: 'Войти с помощью сторонних служб',
        loginError: 'Логин или пароль указаны не верно',
    },
    welcomePage: {
        login: 'Войти',
        contactUs: 'Связаться',
        mainHeader: TenantRuResources.welcomePageMainHeader,
        header: TenantRuResources.welcomePageHeader,
        start: 'Начать',
        policy: 'Privacy Policy',
        terms: 'Terms of Use',
        copyright: TenantRuResources.welcomePageCopyright,
    },
    contactUs: {
        mainHeader: TenantRuResources.contactUsMainHeader,
        header: 'Making Air Cargo Booking Instant and Simple',
        selectLbl: 'Выберите одно',
        gssa: 'GSSA',
        forwarder: 'Экспедитор',
        airport: 'Аэропорт',
        press: 'Пресса',
        name: 'Полное имя',
        email: 'Бизнес почта',
        phone: 'Бизнес телефон',
        company: 'Компания',
        message: 'Сообщение',
        policy: 'Отправляя эту форму, вы соглашаетесь с ',
        contactUs: 'Отправить',
        cancel: 'Отмена',
        error:
            'При попытке отправить ваше сообшение произошла ошибка. Попробуйте позднее или обратитесь к администратору',
    },
    register: {
        header: 'Регистрация пользователя',
        language: 'Выберите предпочитаемый язык',
        languagePlaceholder: 'Выберите язык',
        subHeader: 'Пожалуйста, заполните поля для создания аккаунта',
        organizationName: 'Наименование организации',
        inn: 'ИНН',
        firstName: 'Имя',
        lastName: 'Фамилия',
        email: 'Email',
        phone: 'Мобильный телефон',
        forwarding: 'Экспедирование и логистика',
        other: 'Другое',
        signUp: 'Зарегистрироваться',
        activityType: 'Вид деятельности',
        success: 'Спасибо за обращение в FF Cargo Services. На Вашу электронную почту была отправлена ссылка для создания личного пароля.',
        successShouldModerated:
            'Спасибо за регистрацию на сайте Cargobooking365.\nПосле авторизации модератором Вы получите доступ в систему электронного бронирования. На Ваш e-mail придет ссылка для изменения пароля.',
        country: 'Выберите страну',
    },
    resetPassword: {
        header: 'Сброс пароля',
        subHeader: 'Введите новый пароль',
        newPassword: 'Новый пароль',
        confirmPassword: 'Повторите новый пароль',
        reset: 'Установить новый пароль',
    },
    resetPasswordRequest: {
        header: 'Сброс пароля',
        subHeader: 'Введите свой Email',
        successResetMsg:
            'На вашу электронную почту было отправлено письмо с инструкциями. Эта страница может быть закрыта',
        notFountUserMsg: 'Пользователь не найден',
        email: 'Email',
        reset: 'Сброс',
    },
    changePassword: {
        header: 'Смена пароля',
        subHeader: 'Пожалуйста, введите старый и новый пароль',
        oldPassword: 'Старый пароль',
        newPassword: 'Новый пароль',
        confirmPassword: 'Подтвердите новый пароль',
        update: 'Изменить',
    },
    loginErrors: {
        changePasswordNotMatch: 'Введенные пароли не совпадают',
        somethingWrong: 'Что-то пошло не так. Пожалуйста, свяжитесь с администратором',
        notFoundUserMsg: 'Пользователь не найден',
    },
    home: {
        header: 'Добро пожаловать в AirCargoBooking365',
    },
    request: {
        headers: {
          dimensions: "Детализация габаритов и веса"
        },
        minLettersRequired: 'Мин. 3 буквы необходимо',
        header: TenantRuResources.requestHeader,
        subHeader: 'Выберите лучшую опцию и забронируйте',
        linkText: ' создайте заявку сразу',
        departure: 'Откуда',
        destination: 'Куда',
        from: 'Город или Аэропорт в РФ',
        to: 'Город или Аэропорт',
        nearBy: 'Аэропорты рядом',
        countries: 'Страны',
        airports: 'Аэропорты',
        airCarriers: 'Перевозчики',
        cargoDetails: 'Информация о грузе',
        cargoType: 'Тип груза',
        cargoTypePlaceholder: 'Выберите тип груза...',
        cargoWeight: 'Вес груза',
        cargoWeightTotal: 'Вес груза (общий)',
        natureOfCargo: 'Характер груза',
        requestDate: 'Дата запроса',
        planedFlightDate: 'Плановая дата вылета',
        processingCodes: 'Коды обработки',
        natureOfCargo_placeholder: 'Выберите характер груза',
        goodsDescription: 'Описание груза (не более 50 символов)',
        goodsDescription_placeholder: 'Описание груза для авианакладной',
        cargoVolume: 'Объем груза',
        packagePlaces: 'Кол-во мест',
        dimOfEach: 'Габариты каждого грузового места в отдельности',
        isTurnable: 'Можно переворачивать',
        isCargoStacked: 'Можно штабелировать',
        items: 'Мест',
        itemLength: 'Длина (см)',
        itemWidth: 'Ширина (см)',
        itemHeight: 'Высота (см)',
        itemWeight: 'Вес (кг)',
        weightType: 'Вид расчета',
        generalWeightType: "Общий",
        perPlaceWeightType: "На место",
        itemAdd: 'Добавить',
        total: 'Итого',
        placesNo: 'Количество мест',
        getRoutes: 'Найти маршруты и тарифы',
        excelTemplate: 'Скачать шаблон габаритов',
        excel: 'Загрузить из Excel',
        parseDims: 'Распознать из текста',
        tableHeader: 'Подробное указание весогабритных характеристик груза',
        tableHeaderAddText: 'помогут системе более точно определить доступные опции',
        alertMismatchData: 'Данные в таблице габаритов не соответствуют данным в поле "Общий вес"',
        parse: 'Распознать',
        plannedDepartDate: 'Планируемая дата отправки',
        withoutDimensions: 'Без габаритов',
        separateDimensions: 'Габариты в отдельности',
        useTotalWeight: 'Общий вес',
        useWeightPerPiece: 'Вес каждого груза в отдельности',
        isMillimetersUsed: 'Габариты указаны в миллиметрах',
        serviceModeActive: 'В настоящий момент на портале проводятся технические работы. В ближайшее время портал возобновит свою работу. Приносим свои извинения за доставленные удобства.',
        dimensions: {
            changeTotalWeight: 'Изменить общий вес груза вручную',
            changeSeparatly: 'Ввести вес груза по-отдельности',
            enterTotalWeight: 'Если вы не знаете вес каждого груза в отдельности, введите общий вес груза в разделе "Информация о грузе"',
            enterWeightSeparatly: 'Чтобы ввести вес каждого габарита вручную, удалите общий вес груза в разделе "Информация о грузе"'
        }
    },
    requestSearchResults: {
        header: '',
        departurePoint: 'Пункт отправления',
        destinationPoint: 'Пункт назначения',
        modal: {
            success: 'Письмо успешно отправлено на {email}',
            header: 'Отправить результаты на email(pdf)',
            header2: 'Отправить результаты на почту',
            button: 'Отправить',
            buttonSending: 'Отправка...',
            askDiscountLabel:
                'Задайте Ваш вопрос или укажите дополнительную информацию о грузе: специальные условия обработки, конкурентный тариф и т.д.',
            askDiscountButton: 'Запросить спец.условия',
        },
        currencyExchangeComment:
            'Сравнительный расчет произведен ' +
            'по {exchangeType, select, 557050001 {курсу ЦБ РФ + {cbPlus}%} 557050002 {установленному авиакомпанией фиксированному курсу} other {курсу ЦБ РФ}} ' +
            'на текущую дату. Тарифы перевозчика опубликованы в {currencyCode}. ',
        paymentTerms:
            'Оплата производится в рублях РФ ' +
            'по {exchangeType, select, 557050001 {курсу ЦБ РФ + {cbPlus}%} 557050002 {установленному авиакомпанией фиксированному курсу} other {курсу ЦБ РФ}} ' +
            'за один {currencyCode} на {paymentOn, select, 557050000 {дату платежа} 557050002 {дату авианакладной} other {дату вылета груза из РФ}}. ',
        paymentTermsForBaseCurrency: 'Оплата производится в рублях РФ по тарифам перевозчика.',
        PaymentIsMadeBasedOnTheIssuedInvoice: 'Оплата производится на основании выставленного счета',
        sendSalesDocument: 'Отправить pdf файл с результатами на email',
        sendSalesDocument2: 'Отправить результаты на email',
        downloadSalesDocument: 'Скачать pdf файл с результатами',
        copySalesHtml: 'Скопировать результаты в буфер обмена',
        sendFile: 'Вложением в PDF',
        sendEmail: 'Текстовым письмом',
        sendHeader: 'Отправить результаты',
        sendSchedule: 'Отправить сетку',
        downloadFile: 'Скачать',
        copySales: 'Скопировать',
        products: 'Продукты',
        searchText: 'Идёт поиск лучшей цены и маршрутов для вашей грузоперевозки',
        filter: 'Фильтровать',
        sort: 'Сортировка',
        noConnections: 'Только прямой маршрут',
        sendRequest: 'Запросить бронирование',
        searchResults: 'Результаты поиска',
        found: 'найдено',
        carrier: 'Перевозчик',
        product: 'Продукт',
        status: 'Статус',
        limitationsAndTerms: 'Ограничения и условия',
        optionalFees: 'Терминальная обработка и сопутствующие услуги',
        route: 'Маршрут',
        feeTableHeader: 'Стоимость',
        extraFee: 'Дополнительные сборы',
        airFreightRate: 'Ставка авиаперевозки',
        totalAirFreight: 'Всего по тарифу',
        tariffValidUntil: 'Действует до',
        totalSurcharges: 'Итого сборы',
        totalTariff: 'Итого тариф',
        transit: 'Транзит',
        departure: 'Отправление',
        arrival: 'Прибытие',
        origin: 'Откуда',
        destination: 'Куда',
        limitations: 'Ограничения',
        terms: 'Условия',
        nothingFound:
            'Автоматический поиск не дал результатов.\nПросьба обратиться к оператору на наш e-mail <0 href="mailto:{{email}}">{{email}}</0> для индивидуальной обработки Вашего запроса.',
        filters: 'Фильтры',
        airlines: 'Авиакомпании',
        plannedDepartDate: 'Планируемая дата отправки',
        requestNumber: 'Номер запроса',
        available: 'Доступен',
        uponRequest: 'Доступен по запросу',
        embargo: 'Эмбарго',
        restrictions: 'Ограничения',
        adHoc: 'Спец. тариф',
        promo: 'ПРОМО',
        notAvailable: 'Не доступен',
        saleStatus: 'Статус',
        flightDates: 'Дата вылета',
        optional: 'На выбор',
        showMore: 'Показать больше...',
        showLess: 'Показать меньше...',
        tariffDetails: 'Детали тарифа',
        results: '{count, select, 1 {опция} 2 {опции} 3 {опции} 4 {опции} other {опций}}',
        searchDetails: {
            header: 'Параметры поиска',
            change: 'Изменить',
            seatsNumber: 'Количество мест',
            weight: 'Вес груза',
            volume: 'Объем груза',
            cargoType: 'Тип груза',
            cargoDimensions: 'Габариты грузов по отдельности',
            showMore: 'Подробнее',
            hide: 'Скрыть',
        },
        agreementTitle: 'Соглашение',
        bookingConditions: 'Наличие провозных емкостей по опубликованным тарифам не гарантировано. Перевозчик вправе изменять тарифы в зависимости от спроса. Окончательное подтверждение тарифа и его применения осуществляется при бронировании авиаперевозки.',
        bookingRestrictions: 'В данной отправке не должно содержаться: ценных грузов, опасных грузов, живых животных, грузов, требующих поддержания температурного режима, человеческих останков, военных боеприпасов и других видов оружия, включая спортивное и охотничье, а также других грузов, требующих специальной обработки.',
        agreementConfirmation: 'Подтверждаю',
        agreement:
            'Ознакомлен с тем, что наличие провозных емкостей по опубликованным тарифам не гарантировано. Перевозчик вправе изменять тарифы в зависимости от спроса. Окончательное подтверждение тарифа и его применения осуществляется при бронировании авиаперевозки.',
        agreementNotValidated:
            'Тариф предоставлен без проверки габаритов груза/типа воздушного судна и не гарантирует возможность отправки',
        agreementCanBeChanged:
            'Тариф предоставлен с учетом проверки возможности погрузки, но перевозчик оставляет за собой право поменять воздушное судно. Возможность отправки гарантируется только после подтверждения бронирования авиаперевозчиком',
        agreementGen:
            'Настоящим подтверждаю, что в данной отправке не содержится: ценных грузов, опасных грузов, живых животных, грузов, требующих поддержания температурного режима, человеческих останков, военных боеприпасов и других видов оружия, включая спортивное и охотничье, а также других грузов, требующих специальной обработки.',
        requestQuotation: 'Запросить котировку',
        askDiscount: 'Запросить спец. условия',
        exceedingWeight: 'Превышение допустимого веса груза',
        exceedingDimensions: 'Превышение допустимых габаритов груза',
        autoDelivery: 'Тариф предоставляется по запросу с использованием автодоставки',
        stackableHint: 'Ставка актуальна, если груз можно штабелировать. В противном случае сделайте запрос на котировку',
        airlinesNoteAndConditions: 'Дополнительные условия авиакомпании',
        generalNotesAndCondition: 'Стандартные условия транспортировки',
        embargoAndRestrictions: 'Ограничения и эмбарго',
        hasCustomerApplication: 'Бронирование по данному запросу уже выполнено',
        alternativeDate: 'Другая дата',
    },
    navMenu: {
        settings: 'Настройки',
        changeCurrency: 'Смена валюты',
        changeLang: 'Смена языка',
        changePassword: 'Изменить пароль',
        signOut: 'Выход',
    },
    admin: {
        backToPanel: 'Назад в панель',
        panel: {
            title: 'Админ панель',
            logs: 'Логи',
            logsDescription: 'Просмотр логов',
            referenceData: 'Справочники',
            referenceDataDescription: 'Просмотр справочных данных',
            modules: 'Конфигурация модулей',
            modulesDescription: 'Настройки модулей',
            serviceMode: 'Сервисный режим',
            serviceModeDescription: 'Включение или отключение сервисного режима',
            noServiceMode: 'Не установлен',
            serviceModePeriod: 'Период действия: {start} - {end}',
            serviceModeExpired: 'Истек',
            serviceModeCancel: 'Отмена сервисного режима',
        },
        dictionaries: {
            goBack: 'Обратно',
            title: 'Справочники',
            total: '{0} всего',
            aircrafts: 'Типы самолётов',
            airlines: 'Авиакомпании',
            airplanes: 'Самолёты',
            airportGroups: 'Группы аэропортов',
            airports: 'Аэропорты',
            cargoTypes: 'Типы грузов',
            cities: 'Города',
            countries: 'Страны',
            tariffs: 'Тарифы',
            users: 'Пользователи',
            organizations: 'Организации',
        },
        organizations: {
            search: 'Поиск...',
            name: 'Наименование',
            phone: 'Телефон',
            email: 'Email',
            uniqueNumber: 'ИНН',
            vatNumber: 'КПП',
            regNumber: 'ОГРН',
            hierarchyLevel: 'Уровень иерархии',
            branch: 'Филиал',
            office: 'Офис',
            parent: 'Головная организация',
            related: 'Связанные',
            subsidiary: 'Дочерняя организация',
            goToUsersTable: 'Посмотреть пользователей',
        },
        users: {
            search: 'Поиск...',
            userName: 'Логин',
            email: 'Email',
            organization: 'Организация',
            organizations: 'Организации',
            contactId: 'ИД контакта',
        },
        tariffs: {
            search: 'Поиск...',
            code: 'Код',
            type: 'Тип',
            departureAirportCode: 'Код аэропорта отправления',
            destinationAirportCode: 'Код аэропорта прибытия',
            airlineCode: 'Код авиакомпании',
            currencyCode: 'Код валюты',
            product: 'Продукт',
            buy: 'BUY',
            sell: 'SELL',
            buySell: 'BUY/SELL',
            none: 'NONE',
        },
        airlines: {
            airlines: 'Авиакомпании',
            search: 'Поиск...',
            uploadLogo: 'Загрузить логотип',
            excludeFromCalc: 'Исключить из кал-тора',
            includeToCalc: 'Включить в кал-тор',
            noImage: 'Изображение отсутствует',
            logo: 'Логотип',
            name: 'Название',
            iataPrefix: 'IATA префикс',
            iso2CountryCode: 'ISO2 код страны',
            manage: 'Управление',
            uploadLogoModalTitle: 'Загрузка логотипа для авиакомпании',
            fwbVersion: 'Версия FWB',
            fwb16Version: 'Версия FWB 16',
            fwb17Version: 'Версия FWB 17',
            eAwb: 'Код EAWB',
            security: 'Код безопасности',
            notSpecified: 'Не указано',
            dataSaving: 'Сохранение данных...',
        },
        settings: {
            settings: 'Конфигурация модулей',
            key: 'Ключ',
            value: 'Значение',
            save: 'Сохранить',
            edit: 'Редактировать',
            cancel: 'Отмена',
            language: 'Язык',
            category: 'Категория',
            selectLanguage: 'Язык...',
            deleteModalMessage: 'Вы собираетесь удалить ключ \'{0}\' из настроек.',
            areYouSure: 'Вы уверены?',
            yes: 'Да',
            no: 'Нет',
            userInterface: 'Интерфейс',
            email: 'Эл. почта',
            erase: 'Стереть',
            uniqueErrorMsg: 'Ключ и язык должны быть уникальными.',
            isPublic: 'Публичный',
        },
    },
    customerApplication: {
        booking: 'Бронирование',
        modal: {
            successApplicationTitle:
                'Ваша заявка на бронирование принята в работу и будет обработана в ближайшее время.',
            successQuatationTitle: 'Ваша заявка на котировку принята в работу и будет обработана в ближайшее время.',
            followNextSteps: 'Спасибо за Ваш запрос.\nПожалуйста, используйте номер ниже, чтобы отслеживать его:',
            homePage: 'Домой',
            activeRequests: 'Активные запросы',
        },
        form: {
            awbNumber: 'Номер авианакладной (если есть) / Airwaybill number (if any)',
            date: 'Дата / Date',
            cargoDetails: 'Характер груза (подробно на английском языке)',
            stacked: 'Можно штабелировать / Stackable',
            stackedHint: 'возможность комплектовать груз в штабель в несколько ярусов',
            turnover: 'Можно переворачивать / Turnable',
            turnoverHint: 'допускается перевозка грузов на боковой поверхности',
            packingWithOtherGoods: 'Можно размещать другие грузы сверху / Top load',
            packingWithOtherGoodsHint: 'допускается размещать сверху более легкие грузы',
            carriageSpecialTerms: 'Специальные условия транспортировки / Special terms of Carriage; Код ТН ВЭД / Cargo details and purpose in English; HS Code',
            hazardClass: 'Класс опасности / Hazard class',
            isCargoDangerous: 'Опасный груз / Dangerous cargo',
            cargoUnNumber: 'ООН номер / UN number',
            cargoPackagedForPlaneType: 'Упакован для / Packed for',
            cargoPlane: 'Грузового (Cargo)',
            passengerPlane: 'Пассажирского (Passenger)',
            senderAddress:
                'Имя и адрес отправителя (включая номер телефона) / Shipper\'s Name and Address (including phone number)',
            recipientAddress:
                'Имя и адрес получателя (включая номер телефона и почтовый индекс) / Consignee\'s Name and Address (including phone number and zip code)',
            specialPrice: 'Специальная цена / Special rates',
            refNumber: 'REF номер / REF number',
            awbProvidedBy: 'Авианакладная изготавливается / Airwaybill issued by',
            fromSender: 'Отправителем',
            fromProvider: 'Поставщиком услуг',
            sendRequest: 'Отправить запрос',
            saveRequest: 'Сохранить',
            back: 'Назад',
            cargoPlaces: 'Количество мест',
            cargoVolume: 'Объём груза',
            meters: 'м',
            cargoWeight: 'Физический вес',
            cargoType: 'Тип груза',
            where: 'Откуда',
            to: 'Куда',
            cancel: 'Отмена',
            yes: 'Да',
            no: 'Нет',
            senderStateProvince: 'Штат/Провинция отправителя',
            senderCity: 'Город отправителя',
            recipientStateProvince: 'Штат/Провинция получателя',
            recipientCity: 'Город получателя',
            startTypingHint: 'введите 3 символа чтобы увидеть результаты',
            contactInformation: 'Контакты для обратной связи',
            firstName: 'Имя',
            lastName: 'Фамилия',
            email: 'Ваш Email',
            additionalEmail: 'Доп. Email',
            phoneNumber: 'Номер телефона',
            aggrementTxt:
                'Настоящим удостоверяю, что мною дана полная и исчерпывающая информация о грузе. Я подтверждаю, что груз не содержит запрещенных к перевозке отправлений или ошибочно принятых предметов.',
        },
        traceSubscription: 'Данные по подписке',
    },
    customerApplicationHistory: {
        airportNameOrIata: 'Название аэропорта/IATA код',
        minNumberDigitsRequired: 'введите минимум {value} цифры',
        title: 'История бронирований',
        totalCount: 'Всего',
        nothingFound: 'Ничего не найдено.',
        filter: 'Фильтр',
        filterOptions: 'Параметры фильтра',
        clearFilters: 'Очистить фильтр',
        enterNumber: 'Введите номер...',
        selectAirlines: 'Выберите авиакомпании...',
        selectDateRange: 'Выберите диапазон дат',
        awbNumber: 'Авианакладная',
        dateCreated: 'Дата и время создания',
        startDate: 'С',
        endDate: 'По',
        date: 'Дата',
        number: 'AWB Номер',
        route: 'Маршрут',
        totalFee: 'Цена',
        expectedShippingDate: 'Ожидаемая дата отправления',
        departure: 'Откуда',
        destination: 'Куда',
        minimize: 'Свернуть',
        general: 'Общие сведения',
        actualShippingDate: 'Фактическая дата отправления',
        expectedArrivalDate: 'Ожидаемая дата доставки',
        actualArrivalDate: 'Фактическая дата доставки',
        totalItems: 'Всего упаковок',
        totalWeight: 'Общий вес',
        totalVolume: 'Общий объем',
        dimensionsOfEachPackage: 'Габариты каждой упаковки',
        items: 'Упаковок',
        itemLength: 'Длина упаковки',
        itemWidth: 'Ширина упаковки',
        itemHeight: 'Высота упаковки',
        itemWeight: 'Вес упаковки',
        organizations: 'Организации',
        searchOrganizations: 'Поиск организаций...',
        organizationName: 'Организация',
        repeatSearch: 'Повторить поиск',
        openBookingForm: 'Открыть бронированиe',
        requestNo: 'Номер запроса',
    },
    customerRequestsHistory: {
        additional: "Мест, вес, объем",
        title: 'История запросов',
        totalCount: 'Всего',
        nothingFound: 'Ничего не найдено.',
        date: 'Дата',
        route: 'Направление',
        cargoTypeName: 'Тип груза',
        statusCode: 'Статус',
        filter: 'Фильтр',
        filterOptions: 'Параметры фильтра',
        clearFilters: 'Очистить',
        source: 'Отправление',
        target: 'Прибытие',
        minimize: 'Свернуть',
        expectedShippingDate: 'Ожидаемая дата доставки',
        general: 'Общие сведения',
        searchDate: 'Дата поиска',
        totalItems: 'Всего упаковок',
        totalWeight: 'Общий вес',
        totalVolume: 'Общий объем',
        dimensionsOfEachPackage: 'Габариты каждой упаковки',
        items: 'Упаковок',
        itemLength: 'Длина упаковки',
        itemWidth: 'Ширина упаковки',
        itemHeight: 'Высота упаковки',
        itemWeight: 'Вес упаковки',
        selectDateRange: 'Выберите диапазон дат запроса',
        selectDepartureDateRange: 'Выберите диапазон дат вылета',
        airportOrCity: 'IATA/Аэропорт/Город',
        startDate: 'С',
        endDate: 'По',
        cargoTypes: 'Типы грузов',
        selectCargoTypes: 'Выберите типы грузов...',
        repeatSearch: 'Повторить поиск',
        viewSearchResults: 'Посмотреть запрос',
        numberUpdating: 'Идет обновление данных...',
        status: {
            '852250014': 'В работе',
            '979540002': 'В работе', // 'Требует ручной обработки',
            '852250013': 'В работе', // 'Запрос доп. информации у клиента',
            '852250012': 'В работе', // 'Запрос в авиакомпанию',
            '852250001': 'Завершен', // 'Заключение выгодной сделки',
            '852250004': 'Предложение с расценками', // 'Ожидание решения Заказчика'
            '852250000': 'Предложение с расценками', // 'Предложение с расценками',
            '852250006': 'Предложение с расценками', // 'Предварительное подтверждение',
            '852250009': 'Предложение с расценками', // 'Тендер',
            '1': 'Предложение с расценками', // Обратная связь 1
            '852250003': 'Предложение с расценками', // Обратная связь 2
            '852250005': 'Завершен', // 'Первый груз забронирован',
            '852250007': 'Завершен', // 'Регулярно летает - с нами',
            '852250008': 'Предложение с расценками', // 'Регулярно летает - не с нами',
            '852250010': 'Предложение с расценками', // 'Холодный/сетевой запрос',
            '852250011': 'Предложение с расценками', // 'Изучение рынка',
            '852250015': 'Завершен', // 'Улетел с конкурентами',
            '979540000': 'Завершен', // 'Улетел с ФФ',
            '979540001': 'Завершен', // 'Улетел с АСР',
            '979540003': 'Завершен', // 'Улетел с AGS',
            '979540004': 'Нет доступных опций',
            '979540005': 'Автоматическая тарификация',
        },
    },
    tracing: {
        title: 'Статус',
        request: 'Запрос',
        booking: 'Бронирование',
        execution: 'В пути',
        closing: 'Доставлено',
        arrived: 'Прибыло',
        shipping: 'Доставка',
    },
    generalData: {
        title: 'Общие данные',
        data: 'Данные по грузу',
        edit: 'Редактирование',
    },
    comments: {
        title: 'Комментарии',
        enterComment: 'Введите комментарий',
        send: 'Отправить',
    },
    payment: {
        title: 'Оплата',
    },
    bookingConfirmation: {
        detailsWillBeSoon: 'Детали вашего бронирования будут доступны после его подтверждения',
        title: 'Подтверждение бронирования',
        flightNo: 'Номер рейса',
        originalAirport: 'Откуда',
        destination: 'Куда',
        departureTime: 'Отправление',
        arrivalTime: 'Прибытие',
        dimensions: 'Мест/Вес/Объем',
        bookingStatus: 'Статус бронирования',
        flightDetails: 'Плановая информация о рейсах',
        allIn: 'all-in',
        kg: '/кг',
        min: 'Min',
        normal: 'N',
        tariffHeader: 'Тариф',
        tariff: 'Тариф и дополнительные сборы',
        conditions: 'Условия авиаперевозки',
        transportationCost: 'Предварительная стоимость перевозки с учетом дополнительных сборов',
        fees: 'Дополнительные сборы',
        optionalFees: 'Терминальная обработка и сопутствующие услуги',
        limits: 'Дополнительные условия перевозки и ограничения',
        kk: 'Подтверждено',
        nn: 'Запрос на выделение места',
        ll: 'Список ожидания',
        cn: 'Отмена отмечена',
    },
    printAwbToPdf: {
        printToPdf: 'Печать в PDF',
        original1ForIssuingCarrier: 'Оригинал 1 (для Перевозчика)',
        original2ForConsignee: 'Оригинал 2 (для Получателя)',
        original3ForShipper: 'Оригинал 3 (для Отправителя)',
        copy4DeliveryReceipt: 'Копия 4 (Квитанция о доставке)',
        copy5ExtraCopy: 'Копия 5 (доп. копия)',
        copy6ExtraCopy: 'Копия 6 (доп. копия)',
        copy7ExtraCopy: 'Копия 7 (доп. копия)',
        copy8ForAgent: 'Копия 8 (для Агента)',
        extraCopies: 'Доп. копии',
        downloadPdf: 'Скачать PDF',
    },
    awb: {
        awbListHeader: 'Авианакладные',
        cargoInfo: 'Информация о грузе',
        invalid: 'Некорректен',
        unableToManageHawbDueToAwbInDraft:
            'Невозможно управление хаус авианакладными, поскольку основная авианакладная заполнена не полностью или содержит ошибки.',
        hawbNumber: 'Номер',
        agentName: 'Имя агента',
        agentCity: 'Город агента',
        awb: 'Авианакладная',
        draft: 'Требуется проверка',
        hawb: 'Хаус авианакладные',
        fwb: 'FWB',
        fhl: 'FHL',
        number: 'Номер',
        dateCreated: 'Создан',
        lastUpdate: 'Обновлён',
        backToView: 'Назад',
        createNewEmptyAwb: 'Создать пустую',
        createNewEmptyHawb: 'Создать пустую',
        recognizeAwbFromFile: 'Распознать из файла',
        recognizeHawbFromFile: 'Распознать из файла',
        importFromFwb: 'Импорт из FWB',
        importFromFhl: 'Импорт из FHL',
        sendFhl: 'Отправить FHL',
        sendFwb: 'Отправить FWB',
        createNewAwb: 'Новая авианакладная',
        createNewHawb: 'Новая хаус авианакладная',
        recognizeNewHawb: 'Распознать новую хаус авианакладную из файла',
        editAwb: 'Редактирование авианакладной',
        editHawb: 'Редактирование авианакладной',
        save: 'Сохранить',
        uploadFileWithAwb: 'Загрузка файла с авианакладной',
        cantUploadFile: 'Невозможно загрузить файл. Файл должен быть в формате pdf, jpg или png.',
        recognizing: 'Распознавание авианакладной...',
        recognizingSuccessful: 'Распознавание завершено',
        preview: 'Предпросмотр',
        download: 'Печать в PDF',
        edit: 'Редактировать',
        showFwb: 'Просмотр FWB',
        showFhl: 'Просмотр FHL',
        copyCode: 'Скопировать код',
        copied: 'Скопировано в буфер',
        send: 'Отправить',
        shipper: 'Наименование и адрес отправителя',
        consignmentDetails: 'Детали авианакладной',
        hawbDetails: 'Детали HAWB',
        consignee: 'Наименование и адрес получателя',
        awbNumber: 'Номер AWB',
        name: 'Имя',
        address: 'Адрес',
        postCode: 'Почтовый индекс',
        contact: 'Контактное лицо и телефон',
        rawData: 'Неразобранный адрес',
        departure: 'Отправление',
        issuer: 'Грузоперевозчик',
        issuedBy: 'Выдан',
        accountingInformation: 'Информация по оплате',
        details: 'Подробности',
        issuingCarriersAgent: 'Агент авиакомпании, выдавший грузовую авианакладную',
        nameAndCity: 'Имя и город',
        iataCode: 'Код агента, выданный ИАТА',
        accountNo: 'Номер расчетного счета',
        routingAndFlightBookings: 'Аэропорт отправления (адрес первого перевозчика) и маршрут',
        shipmentReferenceInformation: 'Дополнительная информация о поставке',
        referenceNumber: 'Регистрационный №',
        route: 'Направление',
        to: 'Куда',
        carrier: 'Перевозчик',
        byFirstCarrier: 'Первым перевозчиком',
        by: 'Перевозчиком',
        destinationAirport: 'Конечный аэропорт',
        flightDate: 'Рейс/Дата',
        chargesDeclaration: 'Валюта и страховка',
        currency: 'Валюта',
        valueForCarriage: 'Объявленная ценность для перевозки (число или NVD)',
        valueForCustoms: 'Объявленная ценность для таможни (число или NCV)',
        amountOfInsurance: 'Сумма страхования (число или XXX)',
        other: 'Другое',
        handlingInformation: 'Спец. таможенная информация',
        requirements: 'Информация по обработке груза',
        rateDescription: 'Информация о грузе',
        pieces: 'Количество',
        grossWeight: 'Вес брутто',
        kl: 'K/L',
        rc: 'Rate Class',
        itemNo: 'Номер товара',
        rateToCharge: 'Тариф',
        total: 'Всего',
        natureAndQuantity: 'Характер и количество',
        prepaid: 'Prepaid',
        collect: 'Collect',
        totalPrepaid: 'Total Prepaid',
        totalCollect: 'Total Collect',
        chargesSummary: 'Итоги по авианакладной',
        valuationCharge: 'Valuation Charge',
        chargeableWeight: 'Weight Charge',
        tax: 'Tax',
        otherChargesDueAgent: 'Total Other Charges Due Agent',
        otherChargesDueCarrier: 'Total Other Charges Due Carrier',
        otherCharges: 'Другие сборы',
        description: 'Описание',
        carriersExecution: 'Подпись агента',
        date: 'Дата',
        place: 'Город',
        signature: 'Подпись',
        create: 'Создать',
        cancel: 'Отмена',
        apply: 'Применить',
        importFromFwbCode: 'Импорт',
        stateProvince: 'Штат/Провинция',
        oci: 'OCI',
        country: 'Страна',
        info: 'Инфо',
        customCode: 'Код',
        note: 'Заметка',
        fwbSent: 'FWB отправлен',
        fhlSent: 'FHL отправлен',
        replaceField: 'Заменить',
        skipField: 'Пропустить',
        confirmReplace: 'Заменить на {text}?',
        list: {
            hasFMA: 'FMA',
            number: 'Air Waybill number',
            physicalWeight: 'Физический вес',
            paidWeight: 'Оплачиваемый вес',
            departureDate: 'Дата вылета',
            awbDate: 'Дата транзита',
            currency: 'Валюта',
            departureAirport: 'Вылет',
            arrivalAirport: 'Прилет',
            departureAirportSearch: 'Аэропорт вылета',
            arrivalAirportSearch: 'Аэропорт прилета',
            airline: 'Авиакомпания',
        },
        'custom': 'Код',
        'unknown': 'Пропустить',
        'accountNumber': 'ИНН',
        'tokenType': {
            'Unknown': 'Неизвестный',
            'Address': 'Адрес',
            'Street': 'Адрес',
            'House': 'Адрес',
            'Office': 'Адрес',
            'Place': 'Место',
            'PostalCode': 'Почтовый индекс',
            'Region': 'Адрес',
            'Country': 'Адрес',
            'Name': 'Название',
            'AccountNumber': 'ИНН',
            'Phone': 'Контакт',
        },
        alsoNotify: 'Контактное лицо и телефон для дополнительного уведомления',
        parsing: 'Распознавание адреса. Подождите, пожалуйста.',
        findAddress: 'Найдены распознанные адреса для следующих полей:',
        confirmAddressReplacing: 'Заполнить поля найденными данными? Не забудьте сохранить форму после этого.',
        replace: 'Заменить',
        allowEmptyAccountNumber: 'Нет ИНН',
        findSavedData: 'Найдены сохраненные данные, указанные ранее',
        hasErrors: 'Форма заполнена некорректно',
        delete: 'Удалить',
        confirmDelete: 'Удалить эту хаус-накладную?',
        deletedTemplate: 'Хаус-накладная "{awbNumber}" удалена',
        setUnknownCity: 'Использовать "{city}" в качестве города.',
        itemType: 'городов',
        extraInfo: 'Дополнительная информация',
        dueAgent: 'Расходы агента',
        dueCode: 'Код',
        dueAmount: 'Сумма',
        mergeWarning: 'Позиция будет объединена с имеющейся',
        fwbVersion: 'Версия FWB:',
        weightValue: 'Вес/Ценность',
        recipientAccountTitle: 'Идентификационный номер',
        recipientAllowEmptyAccountNumber: 'Нет идентификационного номера',
        attachOrganizationDocuments: 'Приложите ИНН/ОГРН Вашей организации',
        shcDetails: 'Особые детали',
        eAwb: 'eAwb',
        security: 'Безопасность',
        notSpecified: 'Не указано',
        chgs: 'Может быть введено СС или PP',
        savingChanges: 'Сохранение изменений...',
    },
    customerApplicationEventLog: {
        title: 'Журнал изменений',
        timestamp: 'Дата',
        userDisplayName: 'Пользователь',
        organizationName: 'Организация',
        entity: 'Тип',
        event: 'Событие',
        noEventsFound: 'Событий нет.',
        customerApplication: 'Заявка на бронирование',
        masterAirwaybill: 'Авианакладная',
        houseAirwaybill: 'Хаус авианакладная',
        importedFromImage: 'Загружено из изображения',
        importedFromCode: 'Импортировано из кода',
        fwbSend: 'Send FWB',
    },
    notifications: {
        texts: {
            plural: 'Уведомления',
        },
        settingsDescription: 'Настройки уведомлений',
    },
    eventGroups: 'Группы событий',
    eventGroupType: {
        Registration: 'Регистрация',
        PersonalArea: 'Личный кабинет',
        Requests: 'Запросы',
        Applications: 'Заявки',
    },
    eventTypes: 'Типы событий',
    languages: 'Языки',
    personalAreaEventType: {
        OrganizationUpdated: 'Организация обновлена',
        ContactCreated: 'Контакт создан',
        ContactUpdated: 'Контакт обновлён',
        CanLoginFlagChanged: 'Флаг "может зайти" изменён',
        ActivationFlagChanged: 'Активация/деактивация',
    },
    customerApplicationEventType: {
        Created: 'Создан',
        Updated: 'Обновлён',
        AwbCreated: 'MAWB/HAWB создан',
        AwbRemoved: 'MAWB/HAWB удалён',
        AwbUpdated: 'MAWB/HAWB обновлён',
        AwbImportedFromFile: 'MAWB/HAWB импорт из изображения',
        AwbImportedFromCode: 'MAWB/HAWB импорт из кода',
        FwbSended: 'FWB отправлен',
        BookingRequested: 'Заявка на бронирование',
    },
    customerRequestEventType: {
        Created: 'Создан',
        QuotationRequested: 'Запрос котировки',
        DiscountRequested: 'Запрос скидки',
        ErrorDuringRequest: 'Ошибка при запросе',
    },
    registrationEventType: {
        Registered: 'Регистрация',
        AccreditationInfoUpdated: 'Обновление аккредитационного листа',
    },
    isAccreditationListFilled: 'Аккред. лист заполнен',
    userAgreement: {
        header: 'User agreement',
        checkBoxText: 'Я прочитал и согласен с условиями пользовательского соглашения',
        buttonText: 'Ok',
    },
    pageNotFound: '404 страница не найдена',
    location: 'Город/Аэропорт',
    SingleDatePickerPhrases: {
        calendarLabel: 'Дата',
        hours: 'Часы',
        minutes: 'Минуты',
    },
    trackHistory: {
        dataNotAvailable: 'Данные недоступны',
        youCanTrackOnlyCargoBooking365: 'Вы можете отслеживать только грузы, сделанные в CargoBooking365.com',
        youCanTrackOnlyOrgCargoes: 'Вы можете отслеживать только грузы, сделанные сотрудниками Вашей организации',
        noData: 'Нет данных по отслеживанию',
        trackDataWillBeLater: 'Данные по отслеживанию вашего бронирования будут доступны позднее',
        externalServiceError: 'Ошибка получения данных от внешнего сервиса. Повторите попытку позднее',
        requestInfo: 'Запросить информацию',
        searchExecuting: 'Выполняется поиск',
        enterAwbNumber: 'Введите номер авианакладной',
        dataResponseError: 'Ошибка получения данных',
        resetSearch: 'Сбросить',
        currentStatus: 'Текущий статус',
        title: 'Отслеживание',
        summary: 'Сводка',
        routes: 'Рейсы',
        details: 'Детализация',
        latestInfo: 'Информация',
        origin: 'Пункт отправки',
        destination: 'Пункт назначения',
        flightNo: 'Номер рейса',
        flightDate: 'Дата рейса',
        segment: 'Сегмент',
        confirmed: 'Подтвержден',
        pieces: '{{segment.pieces}} мест',
        segmentWeight: '{{segment.weight}} кг',
        weight: 'Вес',
        codes: {
            ACC: 'Accepted',
            AST: 'Assigned to another flight',
            ARR: 'Arrived',
            ARE: 'Arrival estimated',
            DLV: 'Delivered',
            DDL: 'Documents Delivered',
            RCV: 'Received',
            DEP: 'Departed',
            MAN: 'Manifested',
            BKC: 'Booking Confirmed',
            BKD: 'Booked',
            BKG: 'Booking Generated',
            RCS: 'Received from Shipper',
            RCF: 'Received from Flight',
            NFD: 'Consignee Notified',
            FOH: 'Freight on hand',
            AWD: 'Documentation Delivered',
            CLD: 'Cargo Loaded',
            PRE: 'Shipment Prepared',
            ARV: 'Arrived',
            FWB: 'Electronic AWB',
            TRA: 'In Transit',
            AWR: 'Documents Received',
            TFD: 'Transferred',
            RCT: 'Received from other airline',
            SCW: 'Shipment Checked Into Warehouse',
            CLC: 'Cleared by Customs',
            TPL: 'Temperature Log',
            PIC: 'Available for pickup',
            SOH: 'Shipment on hold',
            PDD: 'Pre-declaration is done',
            MCC: 'Matching cancelled',
            DCD: 'Documentation check is done',
        },
    },
    zohoBooking: {
        Order: 'Забронировать звонок',
        Back: 'Вернуться к выбору времени',
        Messages: {
            'Invalid phone_number': 'Номер телефона указан в неправильном формате',
            'Invalid email': 'Адрес электронной почты указан в неправильном формате',
            'invalid_time_zone': 'Часовой пояс пользователя не определен',
            'general_error': 'Ошибка на форме. Проверьте данные, пожалуйста',
            'slot not available': 'Слот уже занят. Попробуйте выбрать другой.',
        },
    },
    oci: {
        emptyForm: 'Необходимо заполнить поле Страна, Инфо или Код'
    },
    inputPhone: {
        extLabel: 'доб.'
    }
};

export default common;